@use './layout';
@use './typography';
@use './components/button';
@use './components/divider';
@use './components/table';
@use './components/forms';
@use './components/tabs';
@use './components/lists';
@use './components/dialog';
@use './components/menu';

/// Override the "primary" color defined in the design system tokens
:root {
  --alternate-primary: #0766d1;
  --color-primary: var(--alternate-primary);
  --color-text-primary: var(--alternate-primary);
  --primary-normal-bd: var(--alternate-primary);
  --primary-normal-bg: var(--alternate-primary);
  --primary-hover-bd: var(--alternate-primary);
  --primary-hover-bg: var(--alternate-primary);
  --primary-pressed-bd: var(--alternate-primary);
  --primary-pressed-bg: var(--alternate-primary);
  --primary-selected-bd: var(--alternate-primary);
  --primary-selected-bg: var(--alternate-primary);
  --input-active-bd: var(--alternate-primary);
  --input-focused-bd: var(--alternate-primary);
  --input-selected-bd: var(--alternate-primary);
  --input-highlight-bg: var(--alternate-primary);
  --focus-on-light-bg: var(--alternate-primary);
}
