%tab-typography {
  color: var(--color-text-label);
  font-size: var(--text-size-medium);
  font-weight: var(--font-weight-default);

  &:hover {
    color: var(--color-text-default);
  }

  &.active {
    color: var(--color-text-default);
  }
}

%tab-transitions {
  transition: background-color var(--animation-duration-standard)
    var(--animation-timing-standard);
}

.tab-label {
  @extend %tab-typography;
  @extend %tab-transitions;
  display: flex;
  align-items: center;
  padding: var(--space-medium);
  background-color: var(--color-ui-light);
  border-block-end: 1px solid var(--color-text-border);

  &:hover {
    background-color: var(--color-ui-medium);
  }

  &.active {
    border-block-end: 1px solid var(--color-text-default);
  }

  &.tab-label--vertical {
    width: 15rem;
    min-width: 3.25rem;
    align-self: stretch;
    padding: var(--space-small) var(--space-large);
    border-block-end-color: transparent;
    border-block-end-style: solid;
    border-block-end-width: 0;
    color: var(--color-text-default);

    &.active {
      background-color: var(--color-ui-medium);
    }
  }
}

.tabs-header {
  display: flex;
  flex-direction: row;
  flex-flow: nowrap;
  align-items: center;
  border-block-end: 1px solid var(--color-ui-border);

  // for justified tabs, all tabs stretch to fill horizontal space
  &--justified {
    .tab-label {
      flex: 1;
    }
  }

  &.tabs-header--vertical {
    flex-direction: column;
    border: 0;
  }
}

.tab-label__icon {
  display: flex;
  line-height: 1.5;
}

// for tabs that only contain an icon, set specific padding
.tab-label__icon:only-child {
  padding: var(--squish-medium);
}

// vertical, icon-only tabs get a slightly larger padding
.tab-label--vertical .tab-label__icon:only-child {
  padding: var(--squish-large);
}

// if the icon is follow by text, add margins to the left and right
.tab-label__icon + * {
  margin: 0 var(--inline-small);
}
