@import 'libs/shared/ui-style/src/lib/scss/base/_reset.scss';
@import 'mysas';
@import '@angular/cdk/overlay-prebuilt.css';

:root {
  --font-family-primary: 'Anova', -apple-system, blinkmacsystemfont, 'Segoe UI',
    roboto, helvetica, arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  --font-family-secondary: 'Anova', -apple-system, blinkmacsystemfont,
    'Segoe UI', roboto, helvetica, arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

input {
  &:focus {
    outline: none;
  }
}

[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

.br {
  display: block;
}
