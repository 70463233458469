// SAS fonts

@font-face {
  font-family: 'Anova';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/anova/Anova-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Anova';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/anova/Anova-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'Anova';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/anova/Anova-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'Anova';
  font-style: italic;
  font-weight: 300;
  src: url('/assets/fonts/anova/Anova-LightItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Anova';
  font-style: italic;
  font-weight: 300;
  src: url('/assets/fonts/anova/Anova-Italic.woff2') format('woff2');
}
@font-face {
  font-family: 'Anova';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/anova/Anova-BoldItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'AnovaUI';
  src: url('/assets/fonts/anova/AnovaUI-Regular.woff2') format('woff2');
}

html,
body {
  --font-family-primary: 'Anova', -apple-system, blinkmacsystemfont, 'Segoe UI',
    roboto, helvetica, arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  --font-family-secondary: 'Anova', -apple-system, blinkmacsystemfont,
    'Segoe UI', roboto, helvetica, arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: var(--font-weight-normal, 400);
}

html {
  font-family: var(--font-family-primary);
  font-size: var(text-size-default);
}

body {
  color: var(--color-text-default);
  font-size: var(--font-base-size);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: var(--font-line-height);
  text-rendering: optimizespeed;
}

h1,
h2,
h3 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-thick, 700);
}

html,
button,
input,
select,
textarea {
  color: var(--color-text-default);
}

a,
.link {
  color: var(--color-text-primary);
  cursor: pointer;
  text-decoration: none;

  &:visited {
    color: var(--color-text-primary);
    outline: 0;
    text-decoration: none;
  }

  &:focus,
  &:active,
  &:hover {
    color: var(--color-text-primary);
    outline: 0;
    text-decoration: underline;
  }
}

i,
em {
  font-style: var(--font-style-accent);
}

b,
strong {
  font-weight: var(--font-weight-thick);
}

h1 {
  font-size: var(--heading-size-xxx-large);
}

h2 {
  font-size: var(--heading-size-xx-large);
}

h3 {
  font-size: var(--heading-size-x-large);
}

h4 {
  font-size: var(--heading-size-large);
}

h5 {
  font-size: var(--heading-size-medium);
}

h6 {
  font-size: var(--heading-size-small);
}
