@use 'sass:string';
@use 'sass:color';

$gutter-y: calc(var(--space-base-size) * 1.5);
$gutter-x: calc(var(--space-base-size) * 1.5);

$banner-height: 3.75rem;

// used for generating col-* classes that span (up to) 8 columns
$column-sizes: (
  '1': 12.5%,
  '2': 25%,
  '3': 37.5%,
  '4': 50%,
  '5': 62.5%,
  '6': 75%,
  '7': 87.5%,
  '8': 100%,
);

.row {
  display: flex;
  flex-wrap: wrap;
  // margin-block-start: calc(-1 * $gutter-x);
  margin-inline: calc(-0.5 * $gutter-y);

  > * {
    width: 100%;
    max-width: 100%;
    // margin-block-start: $gutter-x;
    padding-inline: calc(0.5 * $gutter-y);
  }

  + .row {
    margin-block-start: $gutter-x;
  }

  &.no-gutters {
    margin-inline: 0;

    > * {
      margin-block-start: 0;
      padding-inline: 0;
    }
  }
}

.col {
  flex: 1 0 0%;
}

// Flex Helpers
@each $name,
  $value
  in (
      start: flex-start,
      end: flex-end,
      center: center,
      baseline: baseline,
      stretch: stretch
    )
{
  .align-items-#{$name} {
    align-items: $value;
  }
  .justify-content-#{$name} {
    justify-content: $value;
  }
}

.d-flex {
  display: flex;
}

@each $col-count, $width in $column-sizes {
  .col-#{$col-count} {
    flex: 1 0 $width;
  }
}

.main-container {
  display: flex;
  height: 100%;
  flex-direction: column;

  .content-container {
    display: flex;
    min-height: calc(100vh - $banner-height);
    flex: 1 1 auto;

    .content-area {
      flex: 1 1 auto;
      padding: calc(var(--space-base-size) * 3.125);
      // margin-block-end: calc(
      //   calc(var(--space-large) * 2) +
      //   calc(var(--font-line-height) * var(--text-size-medium))
      // );
      // background-color: var(--color-ui-medium);
      // overflow-y: auto;
    }
  }
}
