@use '../states';

.label {
  color: var(--color-text-label);
  font-size: var(--text-size-medium);
  font-weight: var(--font-weight-default);

  sup {
    color: var(--color-status-alert);
  }
}

.form-group {
  display: flex;
  flex-flow: column;

  .label {
    display: inline-block;
    margin-block-end: var(--block-x-small);
  }

  &--inline {
    flex-direction: row;
  }
}

// meant to be a wrapper for two or more .form-group divs in a single row
.form-group-row {
  display: flex;
  flex-wrap: wrap;

  // TODO - does this actually apply to all scenarios?
  margin-block-start: var(--block-large);

  .form-group {
    flex: 1;
  }

  .form-group + .form-group {
    margin-inline-start: var(--inline-x-large);
  }

  // + .form-group-row {
  //   margin-top: var(--block-large);
  // }
}

.form-control {
  display: block;
  width: 100%;
}

input[type='text'],
input[type='number'],
input[type='password'],
input[type='email'] {
  @include states.input-state;
  padding: var(--space-small);
  border: 1px solid var(--color-ui-border);
  border-radius: var(--border-radius-xx-small);

  &.form-control-sm {
    padding: var(--space-x-small) var(--space-medium);
    border: 1px solid var(--color-ui-border);
    border-radius: var(--border-radius-xx-small);
  }

  &.ng-touched.ng-invalid {
    padding: var(--space-small)
      calc(var(--space-small) + 14px + var(--inline-small)) var(--space-small)
      var(--space-small);
    border-color: var(--color-status-alert);
    background-image: url("data:image/svg+xml,%3Csvg localeOverrides='%7B%7D' xmlns='http://www.w3.org/2000/svg' class='ErrorStatus' width='14' height='14' version='1.1' viewBox='0 0 14 14' xml:space='preserve'%3E%3Cpath class='svgToolTipPane' fill='none' d='M0 0H14V14H0z'%3E%3C/path%3E%3Cpath class='alert' fill='%23D80000' d='M9.854,9.146c0.195,0.195,0.195,0.512,0,0.707C9.756,9.951,9.628,10,9.5,10 S9.244,9.951,9.146,9.854L7,7.707L4.854,9.854C4.756,9.951,4.628,10,4.5,10S4.244,9.951,4.146,9.854 c-0.195-0.195-0.195-0.512,0-0.707L6.293,7L4.146,4.854c-0.195-0.195-0.195-0.512,0-0.707c0.195-0.195,0.512-0.195,0.707,0L7,6.293 l2.146-2.146c0.195-0.195,0.512-0.195,0.707,0c0.195,0.195,0.195,0.512,0,0.707L7.707,7L9.854,9.146z M14,7c0,3.866-3.134,7-7,7 s-7-3.134-7-7s3.134-7,7-7S14,3.134,14,7z M13,7c0-3.309-2.691-6-6-6S1,3.691,1,7s2.691,6,6,6S13,10.309,13,7z'%3E%3C/path%3E%3C/svg%3E");
    // background-image: url("data:image/svg+xml,%3Csvg localeOverrides='%7B%7D' xmlns='http://www.w3.org/2000/svg' class='ErrorStatus' width='14' height='14' version='1.1' viewBox='0 0 14 14' xml:space='preserve'%3E%3Cpath class='svgToolTipPane' fill='none' d='M0 0H14V14H0z'%3E%3C/path%3E%3Cpath class='alert' fill='var(--color-status-alert)' d='M9.854,9.146c0.195,0.195,0.195,0.512,0,0.707C9.756,9.951,9.628,10,9.5,10 S9.244,9.951,9.146,9.854L7,7.707L4.854,9.854C4.756,9.951,4.628,10,4.5,10S4.244,9.951,4.146,9.854 c-0.195-0.195-0.195-0.512,0-0.707L6.293,7L4.146,4.854c-0.195-0.195-0.195-0.512,0-0.707c0.195-0.195,0.512-0.195,0.707,0L7,6.293 l2.146-2.146c0.195-0.195,0.512-0.195,0.707,0c0.195,0.195,0.195,0.512,0,0.707L7.707,7L9.854,9.146z M14,7c0,3.866-3.134,7-7,7 s-7-3.134-7-7s3.134-7,7-7S14,3.134,14,7z M13,7c0-3.309-2.691-6-6-6S1,3.691,1,7s2.691,6,6,6S13,10.309,13,7z'%3E%3C/path%3E%3C/svg%3E");
    background-position-x: calc(100% - var(--space-small));
    background-position-y: 50%;
    background-repeat: no-repeat;
  }
}

input[type='radio'] {
  @include states.on-off-state;
  display: inline-grid;
  width: 0.875rem;
  height: 0.875rem;
  border-width: 1px;
  margin: 0;
  appearance: none;
  border-radius: 50%;
  place-content: center;
  transform: translateY(-0.075em);

  &::before {
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
    box-shadow: inset 0 0 var(--onoff-normal-bg);
    content: '';
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }

  &:checked::before {
    background-color: var(--color-ui-light);
    transform: scale(1);
  }
}

select {
  @include states.standard-state;

  // this accounts for padding + width of
  // dropdown arrow + small margin to left of arrow
  padding: var(--space-small)
    calc(var(--space-large) + 14px + var(--inline-small)) var(--space-small)
    var(--space-large);
  border-width: 1px;
  border-color: transparent;

  // get rid of default caret
  appearance: none;

  // custom caret
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" class="ArrowSmallDownBtn" width="14" height="14"><path class="svgToolTipPane" fill="none" d="M0 0h14v14H0z"/><path class="iconColor" d="M7 9L4 4h6L7 9z"/></svg>');
  background-position-x: calc(100% - var(--space-large));
  background-position-y: 50%;
  background-repeat: no-repeat;
  border-radius: var(--border-radius-xx-small);
  font-size: var(--text-size-medium);
  font-weight: var(--font-weight-default);
  line-height: 26px;

  &:focus {
    border-color: var(--focus-on-light-bg);
  }

  &.form-control-sm {
    padding: var(--space-x-small)
      calc(var(--space-small) + 14px + var(--inline-x-small))
      var(--space-x-small) var(--space-medium);
    background-position-x: calc(100% - var(--space-small));
  }
}

// wrapper for radio/checkbox + label item
.form-check {
  display: block;
  min-height: 1.25rem;
  margin-block-end: var(--block-small);
}

// class for actual <input> elements
.form-check-input {
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--color-ui-border);
  border-radius: var(--border-radius-xx-small);
  margin-block-start: -1px;
  margin-inline-end: var(--inline-small);
  vertical-align: middle;
}

// class for <label> elements that are inline with a .form-check-input
.form-check-label {
  display: inline-block;
  color: var(--color-text-default);
  font-size: var(--text-size-medium);
  font-weight: var(--font-weight-default);
  margin-inline-start: var(--inline-x-small);
}

.validation-error {
  color: var(--color-status-alert);
  font-size: var(--text-size-x-small);
  margin-block-start: var(--space-x-small);
  opacity: 0.8;
}
