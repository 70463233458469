.divider {
  background-color: var(--color-ui-border);

  &-h {
    width: 100%;
    height: 1px;
  }

  &-v {
    width: 1px;
    height: 100%;
  }
}
