.table {
  width: 100%;
  background-color: var(--color-ui-light);
  color: var(--color-text-default);

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-ui-border);
  }

  // applies formatting to all th and td elements
  > * > * > * {
    padding: var(--squish-medium);
    // border-bottom-width: 1px !important;
    // background-color: var(--color-ui-light);
    color: var(--color-text-default);
    font-size: var(--text-size-medium);
    font-weight: var(--font-weight-default);
  }

  // no bottom border on last row
  tbody > tr:last-of-type > * {
    // border-bottom-width: 0 !important;
  }

  th {
    font-weight: var(--font-weight-thick);
    text-align: start;
  }

  &--hover {
    tbody tr:hover {
      background-color: var(--color-ui-dark);
      cursor: pointer;
    }
  }
}
