@use 'sass:color';

// overrides for MatDialog
.sasds-dialog {
  min-width: 12.5rem !important;
  max-width: calc(100vw - 4rem) !important;
  // min-height: 24rem !important;
  max-height: calc(100vh - 4rem) !important;
  border: 1px solid var(--color-ui-border) !important;
  border-radius: var(--border-radius-x-small) !important;
  box-shadow: var(--depth-large) !important;

  &--sm {
    min-width: 12.5rem !important;
    max-width: 24rem !important;
    min-height: 1rem !important;
    max-height: 50vh !important;
  }

  &--sm-fixed {
    width: 24rem !important;
    height: 40rem !important;
  }

  &--md {
    min-width: 24rem !important;
    max-width: 48rem !important;
    min-height: 1rem !important;
    max-height: 75vh !important;
  }

  &--md-fixed {
    width: 48rem !important;
    height: 60rem !important;
  }

  &--lg {
    min-width: 30rem !important;
    max-width: 72rem !important;
    min-height: 1rem !important;
    max-height: 75vh !important;
  }

  &--lg-fixed {
    width: 72rem !important;
    height: 60rem !important;
  }

  .mat-dialog-container {
    padding: var(--space-x-large) !important;
  }
}

.sasds-dialog__backdrop {
  background: var(--color-ui-light);
  opacity: 0.6 !important;
  transition: all 0.3s ease;
}

// SAS Design System (inspired) styles
.dialog {
  position: relative;
  width: 100%;
  height: 100%;
}

.dialog__content {
  padding: var(--space-xx-large);
}

.dialog__title {
  color: var(--color-text-default);
  font-size: var(--text-size-xx-large);
  font-weight: var(--font-weight-thick);
  line-height: 32px;
  // margin-block-end: var(--space-x-large);
}

.dialog__footer {
  padding: 0 var(--space-medium) var(--space-medium);
}

.dialog__dismiss {
  position: absolute;
  inset-block-start: 10px;
  inset-inline-end: 10px;

  .btn {
    padding: var(--squish-x-small);

    &::after {
      content: url("data:image/svg+xml,%3Csvg localeOverrides='%7B%7D' xmlns='http://www.w3.org/2000/svg' class='Close' width='14' height='14' version='1.1' viewBox='0 0 14 14' xml:space='preserve'%3E%3Cpath class='svgToolTipPane' fill='none' d='M0 0H14V14H0z'%3E%3C/path%3E%3Cpath class='iconColor' d='M10.766,9.713c0.292,0.293,0.292,0.769-0.001,1.061c-0.146,0.146-0.338,0.22-0.53,0.22 s-0.384-0.073-0.531-0.22L7.004,8.07l-2.722,2.712C4.135,10.927,3.944,11,3.752,11c-0.192,0-0.385-0.073-0.531-0.221 c-0.292-0.293-0.292-0.769,0.002-1.061l2.721-2.71L3.219,4.28C2.927,3.987,2.927,3.512,3.22,3.22c0.292-0.293,0.768-0.293,1.061,0 l2.726,2.73l2.714-2.703c0.294-0.292,0.769-0.292,1.061,0.002c0.292,0.293,0.292,0.769-0.002,1.061L8.067,7.01L10.766,9.713z'%3E%3C/path%3E%3C/svg%3E");
    }
  }
}
