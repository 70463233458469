%border-base {
  border-width: 1px;
  border-style: solid;
}

@mixin standard-state {
  @extend %border-base;
  border-color: var(--standard-normal-bd);
  background-color: var(--standard-normal-bg);
  color: var(--standard-normal-fg);

  &:hover {
    border-color: var(--standard-hover-bd);
    background-color: var(--standard-hover-bg);
    color: var(--standard-hover-fg);
  }

  &:focus {
    border-color: var(--focus-on-light-bg);
  }

  &.active,
  &.selected {
    border-color: var(--standard-selected-bd);
    background-color: var(--standard-selected-bg);
    color: var(--standard-selected-fg);
  }

  &.disabled,
  :disabled {
    border-color: var(--standard-disabled-bd);
    background-color: var(--standard-disabled-bg);
    color: var(--standard-disabled-fg);
  }
}

@mixin standard-state-no-bd {
  @extend %border-base;
  border-color: transparent;
  background-color: var(--standard-normal-bg);
  color: var(--standard-normal-fg);

  &:hover {
    border-color: transparent;
    background-color: var(--standard-hover-bg);
    color: var(--standard-hover-fg);
  }

  &:focus {
    border-color: var(--focus-on-light-bg);
  }

  &.active,
  &.selected {
    border-color: transparent;
    background-color: var(--standard-selected-bg);
    color: var(--standard-selected-fg);
  }

  &.disabled,
  :disabled {
    border-color: transparent;
    background-color: transparent;
    color: var(--standard-disabled-fg);
  }
}

@mixin standard-state-no-bd-bg {
  @extend %border-base;
  border-color: transparent;
  background-color: transparent;
  color: var(--standard-normal-fg);

  &:hover {
    border-color: transparent;
    background-color: var(--standard-hover-bg);
    color: var(--standard-hover-fg);
  }

  &:focus {
    border-color: var(--focus-on-light-bg);
  }

  &.active,
  &.selected {
    border-color: transparent;
    background-color: var(--standard-selected-bg);
    color: var(--standard-selected-fg);
  }

  &.disabled,
  :disabled {
    border-color: transparent;
    background-color: transparent;
    color: var(--standard-disabled-fg);
  }
}

@mixin primary-state {
  @extend %border-base;
  border-color: var(--primary-normal-bd);
  background-color: var(--primary-normal-bg);
  color: var(--primary-normal-fg);

  &:hover {
    border-color: var(--primary-hover-bd);
    background-color: var(--primary-hover-bg);
    color: var(--primary-hover-fg);
  }

  &:focus {
    border-color: var(--focus-on-dark-bg);
  }

  &.active,
  &.selected {
    border-color: var(--primary-selected-bd);
    background-color: var(--primary-selected-bg);
    color: var(--primary-selected-fg);
  }

  &.disabled,
  :disabled {
    border-color: var(--primary-disabled-bd);
    background-color: var(--primary-disabled-bg);
    color: var(--primary-disabled-fg);
  }
}

@mixin primary-state-no-bd-bg {
  @extend %border-base;
  border-color: transparent;
  background-color: transparent;
  color: var(--primary-normal-fg);

  &:hover {
    border-color: transparent;
    background-color: var(--primary-hover-bg);
    color: var(--primary-hover-fg);
  }

  &:focus {
    border-color: var(--focus-on-dark-bg);
  }

  &.active,
  &.selected {
    border-color: transparent;
    background-color: var(--primary-selected-bg);
    color: var(--primary-selected-fg);
  }

  &.disabled,
  :disabled {
    border-color: transparent;
    background-color: transparent;
    color: var(--primary-disabled-fg);
  }
}

@mixin secondary-state {
  @extend %border-base;
  border-color: var(--secondary-normal-bd);
  background-color: var(--secondary-normal-bg);
  color: var(--secondary-normal-fg);

  &:focus {
    border-color: var(--focus-on-dark-bg);
  }

  &.active,
  &.selected {
    border-color: var(--secondary-selected-bd);
    background-color: var(--secondary-selected-bg);
    color: var(--secondary-selected-fg);
  }

  &.disabled,
  :disabled {
    border-color: var(--secondary-disabled-bd);
    background-color: var(--secondary-disabled-bg);
    color: var(--secondary-disabled-fg);
  }
}

@mixin secondary-state-no-bd-bg {
  @extend %border-base;
  border-color: transparent;
  background-color: transparent;
  color: var(--secondary-normal-fg);

  &:focus {
    border-color: var(--focus-on-dark-bg);
  }

  &.active,
  &.selected {
    border-color: var(--secondary-selected-bd);
    background-color: var(--secondary-selected-bg);
    color: var(--secondary-selected-fg);
  }

  &.disabled,
  :disabled {
    border-color: transparent;
    background-color: transparent;
    color: var(--secondary-disabled-fg);
  }
}

@mixin on-off-state {
  @extend %border-base;
  border-color: var(--onoff-normal-bd);
  background-color: var(--onoff-normal-bg);
  color: transparent;

  &:read-only,
  &.read-only {
    border-color: var(--onoff-read-only-bd);
    background-color: var(--onoff-read-only-bg);
    color: var(--onoff-read-only-fg);
  }

  &.active,
  &.selected,
  &:checked {
    border-color: transparent;
    background-color: var(--onoff-selected-bg);
    color: var(--onoff-selected-fg);
  }

  &.disabled,
  &:disabled {
    border-color: var(--onoff-disabled-bd);
    background-color: var(--onoff-disabled-bg);
    color: var(--onoff-disabled-fg);
  }

  &.disabled.selected,
  &.disabled.active,
  &.disabled:checked,
  &:disabled.selected,
  &:disabled.active,
  &:disabled:checked {
    border-color: transparent;
    background-color: var(--onoff-selected-disabled-bg);
    color: var(--onoff-selected-disabled-fg);
  }
}

@mixin input-state {
  @extend %border-base;
  border-color: var(--input-normal-bd);
  background-color: var(--input-normal-bg);
  color: var(--input-normal-fg);

  &::placeholder {
    border-color: var(--input-placeholder-bd);
    background-color: var(--input-placeholder-bg);
    color: var(--color-text-label);
  }

  &:hover {
    border-color: var(--input-hover-bd);
    background-color: var(--input-hover-bg);
    color: var(--input-hover-fg);
  }

  &:focus {
    border-color: var(--input-focused-bd);
    background-color: var(--input-focused-bg);
    color: var(--input-focused-fg);
  }

  // TODO - these tokens appear to be missing, using primary instead
  &:focus::selection {
    background-color: var(--primary-selected-bg);
    color: var(--color-text-inverse);
  }

  &.disabled,
  &:disabled {
    border-color: var(--input-disabled-bd);
    background-color: var(--input-disabled-bg);
    color: var(--input-disabled-fg);
  }

  // &:read-only,
  &.read-only {
    border-color: var(--input-readonly-bd);
    background-color: var(--input-readonly-bg);
    color: var(--input-readonly-fg);
  }
}
