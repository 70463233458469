@use '../states';

.btn,
a.btn {
  padding: calc(var(--space-base-size) / 4) var(--space-base-size);
  border-radius: var(--border-radius-xx-small);
  font-size: var(--text-size-medium);
  line-height: 20px;

  &-standard {
    @include states.standard-state;
  }

  &-standard-no-bd {
    @include states.standard-state-no-bd;
  }

  &-standard-no-bd-bg {
    @include states.standard-state-no-bd-bg;
  }

  &-primary {
    @include states.primary-state;
  }

  &-primary-no-bd-bg {
    @include states.primary-state-no-bd-bg;
  }

  &-sm {
    font-size: var(--text-size-small);
  }

  // lightweight appears to simply mean "no border"
  &-lightweight {
    border-color: transparent;
  }

  &:hover {
    text-decoration: none;
  }
}

.btn-group {
  // eliminate all border radius so first/last buttons can override
  .btn {
    border-inline-end-width: 0;
    border-radius: 0;
  }

  .btn:first-child {
    border-inline-start-width: 1px;
    border-radius: var(--border-radius-xx-small) 0 0
      var(--border-radius-xx-small);
  }

  .btn:last-child {
    border-inline-end-width: 1px;
    border-radius: 0 var(--border-radius-xx-small) var(--border-radius-xx-small)
      0;
  }

  .btn.selected {
    border-inline-end-width: 1px;
    border-inline-start-width: 1px;
  }

  .btn.selected + .btn {
    border-inline-start-width: 0;
  }
}

.btn-dropdown {
  display: inline-block;
  padding: var(--space-x-small)
    calc(var(--space-large) + 14px + var(--inline-small)) var(--space-x-small)
    var(--space-x-small);
  background-image: url("data:image/svg+xml,%3Csvg localeOverrides='%7B%7D' xmlns='http://www.w3.org/2000/svg' class='ArrowSmallDownBtn' width='14' height='14' version='1.1' viewBox='0 0 14 14' xml:space='preserve'%3E%3Cpath class='svgToolTipPane' fill='none' d='M0 0H14V14H0z'%3E%3C/path%3E%3Cpath class='iconColor' d='M7,9L4,4h6L7,9z'%3E%3C/path%3E%3C/svg%3E");
  background-position-x: calc(100% - var(--space-small));
  background-position-y: 50%;
  background-repeat: no-repeat;
  text-align: start;
  vertical-align: middle;
  white-space: nowrap;

  // &::after {
  //   display: inline-block;

  //   margin-inline-start: var(--space-small);
  //   vertical-align: -0.155em;
  // }
}
