@use '../states';

.menu {
  min-width: 7rem;
  max-width: 25rem;
  max-height: 75vh;
  margin: var(--block-small) 0;
  background-color: var(--color-ui-light);
}

.menu-group {
  &__header {
    margin: var(--block-small) 0;
    border-block-end: 1px solid var(--color-ui-border);
    cursor: default;
  }

  .menu-item:first-child {
    margin-block-start: var(--block-small);
  }
}

.menu-item {
  @include states.standard-state-no-bd-bg;
  padding: var(--squish-small);
  cursor: pointer;
  font-size: var(--text-size-small);
  font-weight: var(--font-weight-default);

  &--multiline {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &--noop {
    cursor: default;

    &:hover {
      background-color: transparent;
    }
  }

  &__title {
    font-weight: var(--font-weight-thick);
  }

  &__description {
    color: var(--color-text-label);
    font-size: var(--text-size-small);
  }
}

.menu-divider {
  width: 100%;
  border-block-end: 1px solid var(--color-ui-border);
}

// these are overrides for Angular's CDK MatMenu
.sasds-menu {
  min-width: 7rem !important;
  max-width: 25rem !important;
  min-height: 1rem !important;
  max-height: 75vh !important;
  border: 1px solid var(--color-ui-border) !important;
  background-color: var(--color-ui-light) !important;
  border-radius: var(--border-radius-x-small) !important;
  box-shadow: var(--depth-medium) !important;

  .mat-menu-content:not(:empty) {
    padding: 0 !important;
  }
}
