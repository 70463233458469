dl {
  dt {
    font-size: var(--text-size-x-large);
    font-weight: var(--font-weight-thick);
    line-height: 25px;
  }

  dd {
    font-size: var(--text-size-x-large);
    margin-block-end: var(--space-medium);
  }
}
